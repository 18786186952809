import CreateButton from 'components/buttons/CreateButton';
import Card from 'components/card';
import Select from 'components/fields/Select';
import { getMonthlyInvoicesFolder } from 'interfaces/invoice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnterPayement from './components/EnterPayement';
import { useDisclosure } from '@chakra-ui/hooks';

function InvoiceFolder() {
    const nav = useNavigate();
    const [year, setYear] = useState('2024');
    const [monthlyFolders, setMonthlyFolders] = useState([]);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    const fetchMonthlyFolders = async (year: string) => {
        const res = await getMonthlyInvoicesFolder(year);
        if (res && res.data) {
            setMonthlyFolders(res.data);
        }
    };

    const padZero = (num: number) => {
        return num < 10 ? `0${num}` : num;
    };

    const AllInvoicesCard = () => {
        const allTotal = {
            invoices: 0,
            totalHT: 0,
            totalHTNotPaid: 0,
        };
        for (const folder of monthlyFolders) {
            allTotal.invoices += folder.invoices;
            allTotal.totalHT += folder.totalHT;
            allTotal.totalHTNotPaid += folder.totalHTNotPaid;
        }
        return (
            <Card
                extra={`flex flex-col w-full h-[150px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25]`}
            >
                <div
                    className="h-full w-full hover:cursor-pointer"
                    onClick={() => nav(`/invoices/all/${year}`)}
                >
                    <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                        <div className="mb-2">
                            <p className="text-lg font-bold text-navy-700 dark:text-white">
                                {' '}
                                {`Toutes les factures ${year}`}
                            </p>
                            <p className="mt-2 mb-2 text-sm text-[#A3AED0] dark:text-white">
                                {`${allTotal.invoices} factures disponibles`}
                            </p>
                            <p className="mt-2 mb-2 text-sm font-medium text-[#A3AED0] dark:text-white">
                                Facturé: {`${allTotal.totalHT.toFixed(2)} € HT`}
                            </p>
                            {allTotal.invoices > 0 && (
                                <p className="mt-2 mb-2 text-sm font-medium text-[#A3AED0] dark:text-white">
                                    Impayé:{' '}
                                    {`${allTotal.totalHTNotPaid.toFixed(
                                        2,
                                    )} € HT (${(
                                        (allTotal.totalHTNotPaid /
                                            allTotal.totalHT) *
                                        100
                                    ).toFixed()} %)`}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Card>
        );
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }

        fetchMonthlyFolders(year);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    return (
        <div className="col-span-12 h-funn w-full rounded-[20px]">
            <EnterPayement isOpen={isOpen} onClose={onClose} />
            <Card extra={'w-full px-6 pb-6 sm:overflow-x-auto'}>
                <div className="relative flex items-center justify-between pt-4">
                    <div className="grid grid-cols-2">
                        <div className="hidden sm:flex text-xl font-bold text-navy-700 dark:text-white">
                            Mes factures
                        </div>
                        <div>
                            <Select
                                extra="!mt-0 pt-[5px] h-[30px] !w-[140px]"
                                options={[
                                    { label: 'Exercice 2023', value: '2023' },
                                    { label: 'Exercice 2024', value: '2024' },
                                    { label: 'Exercice 2025', value: '2025' },
                                ]}
                                displayKey="label"
                                onSelect={(e: any) => setYear(e.value)}
                                required={false}
                                selected={'2024'}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <div className="invisible lg:visible mt-5">
                            <CreateButton
                                value="Facturer mes clients"
                                extra="my-auto h-10"
                                onClick={() => nav('/generate-invoice')}
                            />
                        </div>
                        <div className="invisible lg:visible mt-5">
                            <button
                                className="my-auto h-10 text-toola-500 border-toola-500 border-2 rounded-xl px-2"
                                onClick={onOpen}
                            >
                                Pointer un paiement
                            </button>
                        </div>
                    </div>
                </div>
                <CreateButton
                    value="Facturer mes clients"
                    extra="my-auto h-10 flex lg:hidden"
                    onClick={() => nav('/generate-invoice')}
                />
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                    <AllInvoicesCard />
                    {monthlyFolders.map((folder, index) => (
                        <Card
                            extra={`flex flex-col w-full h-[150px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25]`}
                        >
                            <div
                                className="h-full w-full hover:cursor-pointer"
                                onClick={() =>
                                    nav(
                                        `/invoices/${padZero(
                                            folder.month + 1,
                                        )}/${year}`,
                                    )
                                }
                            >
                                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                                    <div className="mb-2">
                                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                                            {' '}
                                            {`${months[folder.month]} ${year}`}
                                        </p>
                                        <p className="mt-2 mb-2 text-sm text-[#A3AED0] dark:text-white">
                                            {`${folder.invoices} factures disponibles`}
                                        </p>
                                        <p className="mt-2 mb-2 text-sm font-medium text-[#A3AED0] dark:text-white">
                                            Facturé:{' '}
                                            {`${folder.totalHT.toFixed(
                                                2,
                                            )} € HT`}
                                        </p>
                                        {folder.invoices > 0 && (
                                            <p className="mt-2 mb-2 text-sm font-medium text-[#A3AED0] dark:text-white">
                                                Impayé:
                                                {` ${folder.totalHTNotPaid.toFixed(
                                                    2,
                                                )} € HT (${(
                                                    (folder.totalHTNotPaid /
                                                        folder.totalHT) *
                                                    100
                                                ).toFixed()}%)`}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            </Card>
        </div>
    );
}

export default InvoiceFolder;
