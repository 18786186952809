import { useNavigate } from 'react-router-dom';

const CRMMenu = (props: { path: string }) => {
    const { path } = props;
    const nav = useNavigate();

    return (
        <div className="mt-4 sm:mt-0 ml-5 flex gap-6">
            <p
                className={`${
                    path === '/customers' && 'underline'
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav('/customers')}
            >
                {' '}
                Mes clients
            </p>
            <p
                className={`${
                    path === '/kanban' && 'underline'
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav('/kanban')}
            >
                Mes opportunités
            </p>
            <p
                className={`${
                    path === '/tasks' && 'underline'
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav('/tasks')}
            >
                Mes tâches commerciales
            </p>
            <p
                className={`${
                    path === '/group-pricing' && 'underline'
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav('/group-pricing')}
            >
                Tarifs par groupes
            </p>
        </div>
    );
};

export default CRMMenu;
