import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import InputTagField from 'components/fields/InputTagField';
import SearchSelector from 'components/fields/SearchSelector';
import { getCustomers } from 'interfaces/customer';
import { updateEmailCampaign } from 'interfaces/emailing';
import { getSpecificPricing, updateSpecificPricing } from 'interfaces/pricing';
import { updateMyInfo } from 'interfaces/user';
import { useEffect, useState } from 'react';
import env from 'variables/config';

const UpdatePricing = (props: {
    isOpen: any;
    onClose: any;
    pricingId: any;
    products: any;
}) => {
    const { isOpen, onClose, pricingId, products } = props;
    const [pricing, setPricing] = useState(null);
    const [user, setUser] = useState<any>([]);

    const handleChange = (e: any) => {
        setPricing({
            ...pricing,
            [e.target.id]: e.target.value,
        });
    };

    const initPricing = async () => {
        if (!pricingId) return;
        const res = await getSpecificPricing(pricingId);
        if (res) setPricing(res);
    };

    const handleUpdatePricing = async () => {
        const pricingData = {
            productId: pricing.productId,
            price: pricing.price,
            tags: pricing.tags,
        };
        const res = await updateSpecificPricing(pricingId, pricingData);
        if (res) {
            setPricing(null);
            onClose();
        }
    };

    const initUser = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${env.apiUrl}/user/me`, config)
            .then((res: any) => {
                const user = res.data;
                setUser(user);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const addNewTag = async (newTag: string) => {
        if (!user) return;
        await updateMyInfo({ tags: [...user.tags, newTag] });
    };

    useEffect(() => {
        initPricing();
        initUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingId]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="text-lg font-bold">Modifier le tarif</h1>
                        <div className="mt-5 grid grid-cols-2 gap-2">
                            <div className="z-50 col-span-2">
                                <InputTagField
                                    label="Tags clients"
                                    placeholder=""
                                    id="tags"
                                    type="text"
                                    extra=""
                                    tags={pricing?.tags}
                                    allTags={
                                        user
                                            ? user?.tags?.map(
                                                  (name: string) => ({
                                                      name,
                                                  }),
                                              )
                                            : []
                                    }
                                    onTagCreate={(tag: any) => addNewTag(tag)}
                                    onChange={(tags: any) =>
                                        handleChange({
                                            target: { id: 'tags', value: tags },
                                        })
                                    }
                                />
                            </div>
                            <div className="z-40 col-span-3">
                                <SearchSelector
                                    displayKey="name"
                                    secondaryKey="ref"
                                    label="Produit*"
                                    value={pricing?.productId}
                                    options={products}
                                    onSelect={(e: any) => {
                                        setPricing({
                                            ...pricing,
                                            productId: e._id,
                                            price: e.price,
                                        });
                                    }}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="mt-7 md:mt-5 col-span-2">
                                <InputField
                                    label="Prix unitaire HT*"
                                    placeholder="17"
                                    id="price"
                                    type="number"
                                    value={pricing?.price}
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        <div className="mt-8 flex gap-2 mx-auto">
                            <button
                                onClick={() => {
                                    setPricing(null);
                                    onClose();
                                }}
                                className="linear h-10 rounded-xl border-2 border-red-400 px-2 text-base font-medium text-red-400 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleUpdatePricing}
                                className="linear h-10 rounded-xl border-2 border-toola-500 px-2 text-base font-medium text-toola-500 transition duration-200 hover:bg-toola-600/5 active:bg-red-toola/5"
                            >
                                Modifier
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default UpdatePricing;
