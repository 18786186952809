import { MdEuro, MdPallet } from 'react-icons/md';
import { TbPigMoney, TbMoneybag } from 'react-icons/tb';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import CreateButton from 'components/buttons/CreateButton';
import { getOrderDashboard, getPaginatedOrders } from 'interfaces/order';
import LoadingCard from './components/loadingCard';
import MiniStatistics from 'components/card/MiniStatistics';
import OrderCard from 'components/card/orderCard';
import orderImg from 'assets/img/order.png';
import SearchIcon from 'components/icons/SearchIcon';
import Pagination from 'components/pagination/Pagination';
import ProductSummary from './components/productsSummary';
import ExportMenu from './components/exportMenu';
import { fetchFilter, setFilter } from 'utils/filtersStorage';
import { FaArrowRotateRight } from 'react-icons/fa6';

const ListOrders = () => {
    const nav = useNavigate();
    const [orders, setOrders] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [firstFetch, setFirstFetch] = useState(true);
    const [initSearch, setInitSearch] = useState(true);
    const [limitPerPage, setLimitPerpage] = useState(8);
    const [ordersMeta, setOrdersMeta] = useState(null);
    const [orderType, setOrderType] = useState('all');
    const [toggleState, setToggleState] = useState('pending');
    const isFullyLoaded = orders && dashboard;

    const changeToogleState = (state: string) => {
        setToggleState(state);
        fetchOrders(1, limitPerPage, state);
    };

    const fetchOrders = async (
        page: number,
        limit: number,
        state: string = toggleState,
    ) => {
        setLimitPerpage(limit);
        setOrders(null);
        const res = await getPaginatedOrders(
            page,
            limit,
            state,
            search,
            orderType,
        );
        if (res) {
            setOrders(res.data);
            setOrdersMeta(res.meta);
            setPage(page);
            if (firstFetch) setFirstFetch(false);
        }
    };

    const initOrdersDashboard = async () => {
        const res = await getOrderDashboard();
        if (res) setDashboard(res);
    };

    useEffect(() => {
        fetchOrders(1, limitPerPage, toggleState);
    }, [orderType]);

    useEffect(() => {
        setOrderType('all');
    }, [toggleState]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        const filter = fetchFilter('orders');
        if (filter) {
            setToggleState(filter?.toggleState);
            setSearch(filter?.search);
        } else {
            fetchOrders(page, limitPerPage, toggleState);
        }
        initOrdersDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!firstFetch)
            setFilter('orders', { page, limitPerPage, search, toggleState });
    }, [page, limitPerPage, search, toggleState, firstFetch]);

    useEffect(() => {
        // If init (search = '') but cached search is not empty,return
        const filters = fetchFilter('orders');
        if (initSearch && filters?.search !== '') {
            setInitSearch(false);
            return;
        }

        if (firstFetch) {
            const filters = fetchFilter('orders');
            fetchOrders(
                filters.page || page,
                filters.limitPerPage || limitPerPage,
                filters.toggleState || toggleState,
            );
        } else {
            setOrders(null);
            setOrdersMeta(null);
            const delayDebounceFn = setTimeout(() => {
                fetchOrders(1, limitPerPage, toggleState);
            }, 600);
            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const DiscoverCommandPage = () => {
        return (
            <Card
                extra={
                    'w-full pb-[10px] pt-[10px] px-[29px] h-fit bg-blue-200 shadow shadow-3xl'
                }
            >
                {/* Header */}
                <div className="mt-1">
                    <p className="text-xl font-semibold text-navy-700 dark:text-white">
                        Toola vous fait gagner du temps 🎉
                    </p>
                    <p className="mt-2 text-base text-gray-600">
                        Laissez vos clients commander en toute autonomie avec
                        une page de commande dédié. Les commandes sont
                        automatiquement ajoutées à votre liste de commandes.
                    </p>
                    <CreateButton
                        value="Automatiser en 1 clic"
                        extra="mt-2 my-auto h-10 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                        onClick={() => nav('/setup-automatic-order')}
                    />
                </div>
            </Card>
        );
    };

    return (
        <>
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-3 sm:gap-5 md:grid-cols-2 xl:grid-cols-4">
                    <MiniStatistics
                        name="Commandes"
                        value={
                            isFullyLoaded
                                ? orders.filter(
                                      (c: any) => c.status === 'pending',
                                  ).length
                                : '-'
                        }
                        icon={<MdPallet color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                        extra="sm:hidden"
                    />
                    <MiniStatistics
                        name="Commandes en cours"
                        value={
                            isFullyLoaded
                                ? orders.filter(
                                      (c: any) => c.status === 'pending',
                                  ).length
                                : '-'
                        }
                        icon={<MdPallet color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                        extra="hidden sm:flex"
                    />
                    <MiniStatistics
                        name="CA du jour"
                        value={
                            isFullyLoaded
                                ? `${Math.round(dashboard.revenueOfTheDay)} €`
                                : '-'
                        }
                        icon={<MdEuro color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                    <MiniStatistics
                        name="CA des 7 derniers jours"
                        value={
                            isFullyLoaded
                                ? `${Math.round(dashboard.revenueoftheWeek)} €`
                                : '-'
                        }
                        icon={<TbMoneybag color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                    <MiniStatistics
                        name="CA des 30 derniers jours"
                        value={
                            isFullyLoaded
                                ? `${Math.round(dashboard.revenueOfTheMonth)} €`
                                : '-'
                        }
                        icon={<TbPigMoney color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>
                <div className="col-span-12">
                    <ProductSummary />
                </div>
                {/* <div className="col-span-12">
                    <DiscoverCommandPage />
                </div> */}
                <div className="col-span-12 h-full w-full rounded-[20px]">
                    <Card extra="pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                        {
                            <>
                                <div className="w-full flex items-center m-auto">
                                    <div className="mt-2 flex w-full gap-[2px] overflow-hidden border-b border-gray-200 justify-center flex-row">
                                        <div
                                            className={
                                                toggleState === 'pending'
                                                    ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                                    : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                            }
                                            onClick={() => {
                                                changeToogleState('pending');
                                            }}
                                        >
                                            <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                                                <span className="hidden sm:contents">
                                                    Commandes en cours
                                                </span>
                                                <span className="contents sm:hidden">
                                                    En cours
                                                </span>{' '}
                                                (
                                                {ordersMeta
                                                    ? ordersMeta.pending
                                                    : '-'}
                                                )
                                            </p>
                                        </div>

                                        <div
                                            className={
                                                toggleState === 'delivered'
                                                    ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                                    : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                            }
                                            onClick={() => {
                                                changeToogleState('delivered');
                                            }}
                                        >
                                            <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                                                <span className="hidden sm:contents">
                                                    Commandes livrées
                                                </span>
                                                <span className="contents sm:hidden">
                                                    Livrées
                                                </span>{' '}
                                                (
                                                {ordersMeta
                                                    ? ordersMeta.delivered
                                                    : '-'}
                                                )
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                toggleState === 'canceled'
                                                    ? 'border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                                    : 'pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center'
                                            }
                                            onClick={() => {
                                                changeToogleState('canceled');
                                            }}
                                        >
                                            <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                                                <span className="hidden sm:contents">
                                                    Commandes annulées
                                                </span>
                                                <span className="contents sm:hidden">
                                                    Annulées
                                                </span>{' '}
                                                (
                                                {ordersMeta
                                                    ? ordersMeta.canceled
                                                    : '-'}
                                                )
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex gap-1 mr-2">
                                        <div
                                            className={`flex items-center text-xl hover:cursor-pointer bg-none text-navy-700 bg-lightPrimary py-2 px-2 hover:bg-gray-100 linear justify-center rounded-lg transition duration-200`}
                                            onClick={() =>
                                                nav('/recurring-orders')
                                            }
                                        >
                                            {/* <p className="text-xs text-center">
                                                Commandes récurrentes
                                            </p> */}
                                            <FaArrowRotateRight
                                                className="flex my-auto hover:cursor-pointer"
                                                size={15}
                                                title="Commandes récurrentes"
                                            />
                                        </div>
                                        <ExportMenu />
                                    </div>
                                </div>
                                <div className="mt-[20px] flex flex-col sm:flex-row justify-between items-center gap-y-[10px]">
                                    <div className="flex h-[38px] w-full items-center rounded-xl bg-lightPrimary text-sm text-gray-600 sm:w-[40%]">
                                        <SearchIcon />
                                        <input
                                            type="text"
                                            placeholder="Entreprise, mail, téléphone, contact, ...."
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                        />
                                    </div>
                                    {/* pagination */}
                                    <Pagination
                                        buttons={false}
                                        type="commandes"
                                        maxItems={
                                            ordersMeta
                                                ? ordersMeta.sectionTotal
                                                : 12
                                        }
                                        defaultItemsPerPage={limitPerPage}
                                        itemsPerPage={[8, 16, 24, 32]}
                                        page={page}
                                        onChange={(
                                            page: number,
                                            limit: number,
                                        ) => fetchOrders(page, limit)}
                                    />
                                    <CreateButton
                                        value="Entrer une commande"
                                        extra="my-auto h-10 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                                        onClick={() => nav('/create-order')}
                                    />
                                </div>
                                {toggleState === 'pending' && (
                                    <div className="flex mt-1 ml-4 gap-4">
                                        <p
                                            className={`text-xs ${
                                                orderType === 'all'
                                                    ? 'text-toola-500 underline'
                                                    : 'text-toola-500/80'
                                            } hover:underline hover:cursor-pointer`}
                                            onClick={() => setOrderType('all')}
                                        >
                                            Tout afficher
                                        </p>
                                        <p
                                            className={`text-xs ${
                                                orderType === 'preparing'
                                                    ? 'text-toola-500 underline'
                                                    : 'text-toola-500/80'
                                            } hover:underline hover:cursor-pointer`}
                                            onClick={() =>
                                                setOrderType('preparing')
                                            }
                                        >
                                            Préparation en cours
                                        </p>
                                        <p
                                            className={`text-xs ${
                                                orderType === 'ready'
                                                    ? 'text-toola-500 underline'
                                                    : 'text-toola-500/80'
                                            } hover:underline hover:cursor-pointer`}
                                            onClick={() =>
                                                setOrderType('ready')
                                            }
                                        >
                                            Préparation terminée
                                        </p>
                                    </div>
                                )}
                            </>
                        }
                        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                            {isFullyLoaded && orders.length === 0 && (
                                <div className="col-span-12 mt-10">
                                    <img
                                        className="mx-auto flex h-[250px] w-[250px] items-center justify-center"
                                        src={orderImg}
                                        alt=""
                                    />
                                    <h4 className="col-span-12 my-auto mt-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                        Gérer vos commandes facilement
                                    </h4>
                                    <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                        Vous pouvez aussi créer votre page de
                                        commande pour automatiser leur
                                        réception.
                                    </p>
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() => nav('/create-order')}
                                        >
                                            Créer une page de commande
                                        </button>
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() => nav('/create-order')}
                                        >
                                            Ajouter une commande
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isFullyLoaded
                                ? orders
                                      .filter(
                                          (c: any) => c.status === toggleState,
                                      )
                                      .map((order: any) => {
                                          return (
                                              <OrderCard
                                                  id={order._id}
                                                  company={order.company}
                                                  displayName={
                                                      order.displayName
                                                  }
                                                  orderId={order.orderId}
                                                  deliveryDate={
                                                      order.deliveryDate
                                                  }
                                                  createdAt={order.createdAt}
                                                  items={order.items}
                                                  viewed={order.viewed}
                                                  orderType={orderType}
                                              />
                                          );
                                      })
                                : Array(limitPerPage)
                                      .fill(<LoadingCard />)
                                      .map((order: any) => {
                                          return order;
                                      })}
                        </div>
                        {/* pagination */}
                        {isFullyLoaded && (
                            <Pagination
                                extra="mt-3"
                                text={false}
                                maxItems={
                                    ordersMeta ? ordersMeta.sectionTotal : 12
                                }
                                itemsPerPage={[limitPerPage]}
                                page={page}
                                onChange={(page: number, limit: number) =>
                                    fetchOrders(page, limit)
                                }
                            />
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ListOrders;
