import { dateToFrench } from 'utils/date';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import chevron from '../../../../assets/svg/chevron.svg';
import { useEffect, useState } from 'react';

const weekDays = [
    { en: 'monday', fr: 'lundi', display: 'L' },
    { en: 'tuesday', fr: 'mardi', display: 'M' },
    { en: 'wednesday', fr: 'mercredi', display: 'M' },
    { en: 'thursday', fr: 'jeudi', display: 'J' },
    { en: 'friday', fr: 'vendredi', display: 'V' },
    { en: 'saturday', fr: 'samedi', display: 'S' },
    { en: 'sunday', fr: 'dimanche', display: 'D' },
];

const RecurringOrderCard = (props: {
    company: string;
    displayName: string;
    download?: string;
    extra?: string;
    id: string;
    orderId: string;
    createdAt: string;
    items: any;
    orderType?: string;
    days: any;
}) => {
    const {
        company,
        extra,
        id,
        createdAt,
        items,
        displayName,
        orderType,
        days,
    } = props;
    const nav = useNavigate();
    const [orderReadyStatus, setOrderReadyStatus] = useState(orderType);

    useEffect(() => {
        setOrderReadyStatus(orderType);
    }, [orderType]);

    return (
        <Card
            extra={`flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] ${extra}`}
        >
            <div
                className="h-full w-full hover:cursor-pointer"
                onClick={() => nav(`/recurring-order/${id}`)}
            >
                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            {' '}
                            {displayName || company}{' '}
                        </p>
                        <p className="mt-2 mb-2 text-sm text-[#A3AED0] dark:text-white">
                            {/* {orderId ? "#" : ''} {orderId}{" "} */}
                            {`Créée le ${dateToFrench(
                                createdAt,
                            ).toLocaleLowerCase()}.`}
                        </p>
                        {items &&
                            items
                                .slice(0, 1)
                                .map((item: any, index: number) => {
                                    return (
                                        <p
                                            key={'order_' + index}
                                            className="flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white"
                                        >
                                            <img src={chevron} alt="chevron" />{' '}
                                            {item.quantity} {item.unity}{' '}
                                            {item.name}.
                                        </p>
                                    );
                                })}
                        {items && items.length > 1 && (
                            <p className="pl-[24px] text-sm text-[#A3AED0] dark:text-white">
                                + {items.length - 1} autres produits.
                            </p>
                        )}
                    </div>
                </div>
                <div className="absolute bottom-3">
                    <p className="ml-2 text-sm font-bold text-toola-400 dark:text-white">
                        {days?.map((day: any) => {
                            return weekDays
                                .find((weekDay) => weekDay.en === day)
                                ?.display?.toLocaleUpperCase();
                        })}
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default RecurringOrderCard;
