import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/card';
import CreateButton from 'components/buttons/CreateButton';
import { getOrderDashboard } from 'interfaces/order';
import LoadingCard from './components/loadingCard';
import orderImg from 'assets/img/order.png';
import SearchIcon from 'components/icons/SearchIcon';
import Pagination from 'components/pagination/Pagination';
import { fetchFilter, setFilter } from 'utils/filtersStorage';
import RecurringOrderCard from './components/reccuringOrderCard';
import { getPaginatedRecurringOrders } from 'interfaces/recurringOrder';
import GoBack from 'components/actions/GoBack';

const ListRecurringOrders = () => {
    const nav = useNavigate();
    const [orders, setOrders] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [firstFetch, setFirstFetch] = useState(true);
    const [initSearch, setInitSearch] = useState(true);
    const [limitPerPage, setLimitPerpage] = useState(8);
    const [ordersMeta, setOrdersMeta] = useState(null);
    const [orderType, setOrderType] = useState('all');
    const [toggleState, setToggleState] = useState('pending');
    const isFullyLoaded = orders && dashboard;

    const fetchOrders = async (
        page: number,
        limit: number,
        state: string = toggleState,
    ) => {
        setLimitPerpage(limit);
        setOrders(null);
        const res = await getPaginatedRecurringOrders(page, limit, search);
        if (res) {
            setOrders(res.data);
            setOrdersMeta(res.meta);
            setPage(page);
            if (firstFetch) setFirstFetch(false);
        }
    };

    const initOrdersDashboard = async () => {
        const res = await getOrderDashboard();
        if (res) setDashboard(res);
    };

    useEffect(() => {
        fetchOrders(1, limitPerPage, toggleState);
    }, [orderType]);

    useEffect(() => {
        setOrderType('all');
    }, [toggleState]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        const filter = fetchFilter('orders');
        if (filter) {
            setToggleState(filter?.toggleState);
            setSearch(filter?.search);
        } else {
            fetchOrders(page, limitPerPage, toggleState);
        }
        initOrdersDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!firstFetch)
            setFilter('orders', { page, limitPerPage, search, toggleState });
    }, [page, limitPerPage, search, toggleState, firstFetch]);

    useEffect(() => {
        // If init (search = '') but cached search is not empty,return
        const filters = fetchFilter('orders');
        if (initSearch && filters?.search !== '') {
            setInitSearch(false);
            return;
        }

        if (firstFetch) {
            const filters = fetchFilter('orders');
            fetchOrders(
                filters.page || page,
                filters.limitPerPage || limitPerPage,
                filters.toggleState || toggleState,
            );
        } else {
            setOrders(null);
            setOrdersMeta(null);
            const delayDebounceFn = setTimeout(() => {
                fetchOrders(1, limitPerPage, toggleState);
            }, 600);
            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                <GoBack text="Commandes" />
                <div className="col-span-12 h-full w-full rounded-[20px]">
                    <Card extra="pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                        {
                            <>
                                <div className="mt-[20px] flex flex-col sm:flex-row justify-between items-center gap-y-[10px]">
                                    <div className="flex h-[38px] w-full items-center rounded-xl bg-lightPrimary text-sm text-gray-600 sm:w-[40%]">
                                        <SearchIcon />
                                        <input
                                            type="text"
                                            placeholder="Entreprise, mail, téléphone, contact, ...."
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                            className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                        />
                                    </div>
                                    <CreateButton
                                        value="Créer une commande récurrente"
                                        extra="text-sm my-auto h-10 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                                        onClick={() =>
                                            nav('/create-recurring-order')
                                        }
                                    />
                                </div>
                            </>
                        }
                        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                            {isFullyLoaded && orders.length === 0 && (
                                <div className="col-span-12 mt-10">
                                    <img
                                        className="mx-auto flex h-[250px] w-[250px] items-center justify-center"
                                        src={orderImg}
                                        alt=""
                                    />
                                    <h4 className="col-span-12 my-auto mt-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                        Aucune commande récurrente
                                    </h4>
                                    <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                        Vous et vos clients pouvez gérer vos
                                        commandes récurrentes pour gagner du
                                        temps
                                    </p>
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() =>
                                                nav('/create-recurring-order')
                                            }
                                        >
                                            Ajouter une commande récurrente
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isFullyLoaded
                                ? orders.map((order: any) => {
                                      return (
                                          <RecurringOrderCard
                                              id={order._id}
                                              company={order.company}
                                              displayName={order.displayName}
                                              orderId={order.orderId}
                                              createdAt={order.createdAt}
                                              items={order.items}
                                              orderType={orderType}
                                              days={order.days}
                                          />
                                      );
                                  })
                                : Array(limitPerPage)
                                      .fill(<LoadingCard />)
                                      .map((order: any) => {
                                          return order;
                                      })}
                        </div>
                        {/* pagination */}
                        {isFullyLoaded && (
                            <Pagination
                                extra="mt-3"
                                text={false}
                                maxItems={
                                    ordersMeta ? ordersMeta.sectionTotal : 12
                                }
                                itemsPerPage={[limitPerPage]}
                                page={page}
                                onChange={(page: number, limit: number) =>
                                    fetchOrders(page, limit)
                                }
                            />
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ListRecurringOrders;
