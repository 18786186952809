export const productColorList = [
    {
        name: 'Aucune couleur',
        id: '',
        _id: '',
    },
    {
        name: 'Blanc',
        id: '#ffffff',
        _id: '#ffffff',
    },
    {
        name: 'Rouge',
        id: '#ff0000',
        _id: '#ff0000',
    },
    {
        name: 'Noir',
        id: '#000000',
        _id: '#000000',
    },
    {
        name: 'Vert',
        id: '#9ccf7a',
        _id: '#9ccf7a',
    },
    {
        name: 'Bleu',
        id: '#0000ff',
        _id: '#0000ff',
    },
    {
        name: 'Jaune',
        id: '#ffff00',
        _id: '#ffff00',
    },
    {
        name: 'Cyan',
        id: '#00ffff',
        _id: '#00ffff',
    },
    {
        name: 'Magenta',
        id: '#ff00ff',
        _id: '#ff00ff',
    },
    {
        name: 'Gris',
        id: '#808080',
        _id: '#808080',
    },
    {
        name: 'Orange',
        id: '#ffa500',
        _id: '#ffa500',
    },
    {
        name: 'Violet',
        id: '#800080',
        _id: '#800080',
    },
    {
        name: 'Rose',
        id: '#ffc0cb',
        _id: '#ffc0cb',
    },
];
