import Card from 'components/card';
import CreatePricingModal from './components/CreatePricingModal';
import { useDisclosure } from '@chakra-ui/hooks';
import { useEffect, useState } from 'react';
import UpdatePricing from './components/updateReminderModal';
import { getPricingTags } from 'interfaces/pricing';
import CRMMenu from 'views/crm/list-customers/components/CRMMenu';
import { getProducts } from 'interfaces/products';

const PricingSettings = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenUpdate,
        onOpen: onOpenUpdate,
        onClose: onCloseUpdate,
    } = useDisclosure();
    const [pricings, setPricings] = useState([]);
    const [selectedPricing, setSelectedPricing] = useState(null);
    const [products, setProducts] = useState([]);

    const initPricingTags = async () => {
        const res = await getPricingTags();
        if (res) setPricings(res);
    };

    const initProducts = async () => {
        const res = await getProducts();
        if (res && res.data) setProducts(res.data);
    };

    useEffect(() => {
        initPricingTags();
        initProducts();
    }, []);

    const GroupPricing = (props: { pricing: any; onClick: any }) => {
        const { pricing, onClick } = props;

        return (
            <div
                className="mt-1 relative col-span-2 rounded-lg p-4 border shadow cursor-pointer hover:shadow-lg"
                onClick={onClick}
            >
                <h4 className="text-sm font-medium">
                    Tags: {pricing.tags.join(', ')}
                </h4>
                <p className="mt-1 text-xs">
                    {pricing?.name} - {pricing.price} €
                </p>
            </div>
        );
    };

    return (
        <div className="">
            {' '}
            <CRMMenu path="/group-pricing" />
            <Card extra={'w-full mt-5 px-6 py-6 border'}>
                {/* Header */}

                <CreatePricingModal
                    isOpen={isOpen}
                    products={products}
                    onClose={() => {
                        initPricingTags();
                        onClose();
                    }}
                />
                <UpdatePricing
                    isOpen={isOpenUpdate}
                    pricingId={selectedPricing}
                    products={products}
                    onClose={() => {
                        setSelectedPricing(null);
                        initPricingTags();
                        onCloseUpdate();
                    }}
                />
                <div className="w-full px-1 flex justify-between">
                    <div>
                        <h4 className="text-xl font-medium">
                            Tarifs par groupes clients
                        </h4>
                        <p className="mt-1 text-sm">
                            Attribuez des tarifs spécifiques à vos groupes de
                            clients
                        </p>
                    </div>
                    <button
                        className="linear flex h-7 cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100"
                        onClick={onOpen}
                    >
                        Créer un nouveau tarif
                    </button>
                </div>
                {/* inputs */}
                <div className="mt-3 grid grid-cols-1 gap-3 md:grid-cols-4">
                    {pricings &&
                        pricings.map((pricing: any, index: number) => (
                            <GroupPricing
                                pricing={pricing}
                                onClick={() => {
                                    setSelectedPricing(pricing._id);
                                    onOpenUpdate();
                                }}
                            />
                        ))}

                    {pricings?.length === 0 && (
                        <div className="mt-10 col-span-4 flex justify-center">
                            <p className="text-xl text-gray-500">
                                Aucun tarif trouvé
                            </p>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default PricingSettings;
