import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';

import InvoicesTable from './components/invoicesTable';
import {
    getPaginatedUnpaidInvoices,
    getUnpaidInvoicesData,
    sendInvoiceEmail,
    updateEmailModel,
} from 'interfaces/invoice';
import Pagination from 'components/pagination/Pagination';
import EmailModal from './components/EmailModal';
import PieChartCard from './components/PieChartCard';
import GoBack from 'components/actions/GoBack';
import MiniStatistics from 'components/card/MiniStatistics';
import { MdCalendarMonth, MdEuro, MdPeople } from 'react-icons/md';
import { TbFileInvoice } from 'react-icons/tb';

const UnpaidManager = () => {
    const nav = useNavigate();
    const params = useParams();
    const [invoices, setInvoices] = useState(null);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [invoicesMeta, setInvoicesMeta] = useState(null);
    const [customersDebts, setCustomersDebts] = useState(null);

    const {
        isOpen: isEmailSenderModalOpen,
        onOpen: onEmailSenderModalOpen,
        onClose: onEmailSenderModalClose,
    } = useDisclosure();

    const onEmailModalOpen = (invoiceId: string) => {
        setSelectedInvoiceId(invoiceId);
        onEmailSenderModalOpen();
    };

    const fetchInvoices = async (page: number, limit: number) => {
        const month = Number(params.month);
        const year = Number(params.year);
        const res = await getPaginatedUnpaidInvoices(limit, page, month, year);
        if (res && res.data) {
            setInvoices(res.data.data);
            setInvoicesMeta(res.data.meta);
            setPage(page);
            setLimit(limit);
        }
    };

    const initCustomerDebts = async () => {
        const month = Number(params.month);
        const year = Number(params.year);
        const res = await getUnpaidInvoicesData(month, year);
        if (res && res.data) {
            setCustomersDebts(res.data);
        }
    };

    const sendEmail = async (name: string, content: string) => {
        const res = await updateEmailModel(name, content);
        if (res) {
            onEmailSenderModalClose();
            const invoice = await sendInvoiceEmail(
                selectedInvoiceId,
                name,
                content,
            );
            if (invoice) {
                window.location.reload();
            }
        }
    };

    const onFilter = async (newSearch: string, newStatus: string) => {
        if (newStatus) {
            fetchInvoices(1, limit);
        }
    };

    const onRefresh = () => {
        fetchInvoices(page, limit);
    };

    // Default init
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        fetchInvoices(page, limit);
        initCustomerDebts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-5 md:mt-0">
            <GoBack />
            <div className="mt-1 grid h-full w-full grids-col-1 md:grid-cols-4 gap-5">
                <div>
                    <MiniStatistics
                        name="Période"
                        value={
                            params.month === 'all'
                                ? `${params.year}`
                                : `${params.month}/${params.year}`
                        }
                        icon={<MdCalendarMonth color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>
                <div>
                    <MiniStatistics
                        name="Impayés sur la période"
                        value={customersDebts?.totalLeftToPay}
                        icon={<MdEuro color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>
                <div>
                    <MiniStatistics
                        name="Factures impayées"
                        value={customersDebts?.invoicesNbr}
                        icon={<TbFileInvoice color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>
                <div>
                    <MiniStatistics
                        name="Clients concernés"
                        value={
                            customersDebts?.customers
                                ? Object.keys(
                                      customersDebts?.customers,
                                  )?.length?.toFixed()
                                : ''
                        }
                        icon={<MdPeople color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>
            </div>
            <div className="mt-2 grid h-full w-full grid-cols-5 gap-5">
                <EmailModal
                    isOpen={isEmailSenderModalOpen}
                    onClose={onEmailSenderModalClose}
                    sendEmail={(n: string, object: string) =>
                        sendEmail(n, object)
                    }
                    selectedInvoiceId={selectedInvoiceId}
                />
                <div className="col-span-5 md:col-span-3 h-full w-full rounded-[20px]">
                    {invoices && (
                        <InvoicesTable
                            tableData={invoices}
                            onOpen={() => {}}
                            onEmailSenderModalOpen={onEmailModalOpen}
                            invoicesMeta={invoicesMeta}
                            onFilter={(search: string, status: string) =>
                                onFilter(search, status)
                            }
                            onRefresh={onRefresh}
                        />
                    )}
                    <Pagination
                        extra="mt-2 col-span-12"
                        text={false}
                        type="factures"
                        maxItems={invoicesMeta ? invoicesMeta.total : 12}
                        itemsPerPage={[5, 10, 15, 20]}
                        page={page}
                        onChange={(page: number, limit: number) =>
                            fetchInvoices(page, limit)
                        }
                    />
                </div>
                <div className="mt-1 col-span-5 md:col-span-2 h-full w-full rounded-[20px]">
                    <PieChartCard data={customersDebts?.customers} />
                </div>
            </div>
        </div>
    );
};

export default UnpaidManager;
