import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Card from 'components/card';
import InputField from 'components/fields/InputField';
import SearchSelector from 'components/fields/SearchSelector';
import { getCustomerLastOrder, getCustomers } from 'interfaces/customer';
import { getProducts } from 'interfaces/products';
import OrderTable from './components/OrderTable';
import { getTotalHT } from './utils';
import { BsStars } from 'react-icons/bs';
import { calculateTotalPurchasePrice } from 'utils/margin';
import { createRecurringOrder } from 'interfaces/recurringOrder';
import GoBack from 'components/actions/GoBack';

const weekDays = [
    { en: 'monday', fr: 'lundi', display: 'L' },
    { en: 'tuesday', fr: 'mardi', display: 'M' },
    { en: 'wednesday', fr: 'mercredi', display: 'M' },
    { en: 'thursday', fr: 'jeudi', display: 'J' },
    { en: 'friday', fr: 'vendredi', display: 'V' },
    { en: 'saturday', fr: 'samedi', display: 'S' },
    { en: 'sunday', fr: 'dimanche', display: 'D' },
];

const CreateRecurringOrder = () => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<any>([]);
    const [warning, setWarning] = useState<any>(null);
    const [products, setProducts] = useState<any>([]);
    const [IARefreshCounter, setIARefreshCounter] = useState<number>(0);
    const [displayOrderContent, setDisplayOrderContent] =
        useState<boolean>(false);
    const [customerLastOrder, setCustomerLastOrder] = useState<any>(null);
    const [newOrder, setNewOrder] = useState<any>({
        items: [],
        totalPrice: 0,
        customer: null,
        days: ['monday', 'wendesday', 'saturday'],
    });
    const [newItem, setNewItem] = useState<any>({
        discountType: 'none',
        discountValue: 0,
    });

    const handleChange = (e: any) => {
        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const handleItemChange = (e: any) => {
        setNewItem((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const updateQuantity = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).quantity = value;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const updateComment = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).comment = value;
        setNewOrder({
            ...newOrder,
        });
    };

    const updateUnityPrice = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).price = value;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const updateDiscount = (product: any, value: number) => {
        newOrder.items.find((item: any) => item === product).discountValue =
            value;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const updateDiscountType = (product: any, value: string) => {
        newOrder.items.find((item: any) => item === product).discountType =
            value;

        if (value === 'none')
            newOrder.items.find((item: any) => item === product).discountValue =
                0;
        newOrder.totalPrice = getTotalHT(newOrder.items);
        setNewOrder({
            ...newOrder,
        });
    };

    const deleteItem = (item: any) => {
        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            items: prevCustomer.items.filter(
                (i: any) => i.productId !== item.productId,
            ),
            totalPrice: getTotalHT(
                prevCustomer.items.filter(
                    (i: any) => i.productId !== item.productId,
                ),
            ),
        }));
    };

    const initCustomers = async () => {
        const res = await getCustomers();
        const newCustomers = res.data.map((customer: any) => ({
            ...customer,
            company: customer.displayName || customer.company,
        }));
        if (res) setCustomers(newCustomers);
    };

    const initProducts = async (customer: string) => {
        const res = await getProducts(customer);
        if (res) setProducts(res.data);
    };

    const cleanNewOrder = (order: any) => {
        const newItems = order.items.map((item: any) => {
            const newItem = { ...item };
            if (item.price === '') newItem.price = 0;
            delete newItem.totalPurchasePrice;
            return newItem;
        });
        return { ...order, items: newItems };
    };

    const addOrder = async (createOne = true) => {
        const res = await createRecurringOrder(cleanNewOrder(newOrder));
        if (res) {
            toast.success('Commande ajouté');
            if (createOne) navigate('/recurring-orders');
            else window.location.reload();
        }
    };

    useEffect(() => {
        console.log(newOrder);
    }, [newOrder]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initCustomers();
        initProducts(newOrder ? newOrder.customer : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateProductsPrices = async (products: any) => {
        const newItemsUpdated = products.find(
            (product: any) => product._id === newItem._id,
        );
        let isNewOrderUpdated = false;
        const newOrdersItemsUpdated = newOrder.items.map((item: any) => {
            const itemUpdated = products.find(
                (product: any) => product._id === item._id,
            );
            if (itemUpdated) {
                item.price = itemUpdated.price;
                isNewOrderUpdated = true;
            }
            return item;
        });

        const customer = customers.find(
            (customer: any) => customer._id === newOrder.customer,
        );
        const warning = `Prix unitaire HT modifié en fonction du prix négocié avec ${
            customer ? customer.company : 'le client'
        }.`;
        if (newItemsUpdated !== undefined) {
            setWarning(warning);
            setNewItem((prevCustomer: any) => ({
                ...prevCustomer,
                price: newItemsUpdated.price,
            }));
        }

        if (isNewOrderUpdated) {
            setWarning(warning);
            setNewOrder((prevCustomer: any) => ({
                ...prevCustomer,
                items: newOrdersItemsUpdated,
                totalPrice: getTotalHT(newOrdersItemsUpdated),
            }));
        }
    };

    const setOrderWithAI = () => {
        if (newOrder.customer === null) {
            return toast.error('Veuillez sélectionner un client');
        }

        for (let i = 0; i < customerLastOrder.length; i++) {
            const item = customerLastOrder[i];
            const product = products.find(
                (product: any) => product._id === item.productId,
            );
            if (product) {
                item.totalPurchasePrice = calculateTotalPurchasePrice(product);
            }
        }

        if (customerLastOrder && customerLastOrder.length > 0) {
            setNewOrder({
                ...newOrder,
                items: customerLastOrder,
                totalPrice: getTotalHT(customerLastOrder),
            });
            updateProductsPrices(products);
        }
    };

    const fetchProductsPrices = async (customer: string) => {
        const res = await getProducts(customer);
        if (res) {
            setProducts(res.data);
            updateProductsPrices(res.data);
        }
    };

    const fetchLastOrder = async (customer: string) => {
        if (!customer) return;
        const res = await getCustomerLastOrder(customer);
        if (res) {
            console.log(res);
            setCustomerLastOrder(res);
        }
    };

    const updateDays = (e: any, day: string) => {
        const checkboxState = e.target.checked;
        const days = [...newOrder.days];

        if (checkboxState) {
            days.push(day);
        } else {
            const index = days.indexOf(day);
            days.splice(index, 1);
        }

        const uniqueDays = Array.from(new Set(days));

        setNewOrder((prevCustomer: any) => ({
            ...prevCustomer,
            days: uniqueDays,
        }));
    };

    // Fetch products prices when the AI button is clicked
    useEffect(() => {
        fetchProductsPrices(newOrder ? newOrder.customer : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IARefreshCounter]);

    useEffect(() => {
        fetchLastOrder(newOrder ? newOrder.customer : null);
        fetchProductsPrices(newOrder ? newOrder.customer : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newOrder.customer]);

    const alreadyExist = (product: any) => {
        return newOrder.items.find(
            (product: any) => product.productId === newItem.productId,
        );
    };

    const isValidOrder = (order: any) => {
        return (
            order.items.length > 0 && order.customer && order.totalPrice >= 0
        );
    };

    const totalBrutWeight = () => {
        return newOrder.items
            .reduce(
                (acc: number, item: any) =>
                    acc +
                    (isNaN(Number(item.brutWeight * item.quantity))
                        ? 0
                        : Number(item.brutWeight * item.quantity)),
                0,
            )
            ?.toFixed(2);
    };

    const totalNetWeight = () => {
        return newOrder.items
            .reduce(
                (acc: number, item: any) =>
                    acc +
                    (isNaN(Number(item.netWeight * item.quantity))
                        ? 0
                        : Number(item.netWeight * item.quantity)),
                0,
            )
            ?.toFixed(2);
    };

    return (
        <div className="mt-3 grid  grid-cols-12">
            <GoBack text="Commandes" />
            <div className={`mt-2 col-span-12`}>
                <Card extra={'w-full !p-5 border'}>
                    {/* Header */}
                    <div className="h-full w-full">
                        <div>
                            <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                Commande récurrente
                            </h4>
                            <p className="mt-2 text-sm">
                                💡 Les commandes récurrentes sont générées
                                chaque nuit en fonction du délai minimum de
                                livraison de la tournée associée au client.
                            </p>
                            <p className="mt-2 ml-2 text-sm">
                                Par exemple, les premières commandes créées
                                seront celles dont la date de livraison est
                                égale à aujourd'hui + délai minimum de
                                livraison.
                            </p>
                            <p className="ml-2 text-sm">
                                Si le délai minimum est de 2 jours, une commande
                                avec une date de livraison dans 2 jours sera
                                créée cette nuit.
                            </p>
                            <p className="mt-2 ml-2 text-sm">
                                Si une commande récurrente est programmé pour un
                                jour qui est désactivé au niveau de la tournée,
                                la commande ne sera pas créée.
                            </p>

                            <div className="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-12">
                                <div className="z-50 col-span-3">
                                    <SearchSelector
                                        label="Client*"
                                        displayKey="company"
                                        options={customers}
                                        onSelect={(e: any) => {
                                            setNewOrder(
                                                (prevCustomer: any) => ({
                                                    ...prevCustomer,
                                                    customer: e._id,
                                                }),
                                            );
                                        }}
                                        inputSmall={true}
                                    />
                                </div>
                                <div className="col-span-6">
                                    <InputField
                                        label="Note du client"
                                        placeholder={`Pouvez-vous livrer avant 8h ?`}
                                        id="customerNotes"
                                        type="text"
                                        onChange={handleChange}
                                        inputSmall={true}
                                    />
                                </div>
                            </div>
                            <div className="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-12">
                                <div className="col-span-3">
                                    <label
                                        htmlFor={'Note du client'}
                                        className={`text-xs font-medium text-navy-700`}
                                    >
                                        Jours de commande
                                    </label>
                                    <div className="mt-1 flex items-center mb-4 gap-3">
                                        {weekDays.map((day, index) => (
                                            <input
                                                key={`${index}-checkbox`}
                                                id={day.en}
                                                type="checkbox"
                                                checked={newOrder.days.includes(
                                                    day.en,
                                                )}
                                                onChange={(e) =>
                                                    updateDays(e, day.en)
                                                }
                                                className="w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            ></input>
                                        ))}
                                    </div>
                                    <div className="flex items-center text-center mb-4 gap-5">
                                        {weekDays.map((day, index) => (
                                            <label
                                                key={`${index}-label`}
                                                htmlFor="default-checkbox"
                                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                {day.display}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <Card extra={'mt-3 w-full !p-5 border'}>
                    <div className="h-full w-full">
                        <div>
                            <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                Contenu de la commande
                            </h4>
                            {warning && (
                                <p className="mt-2 text-sm text-red-500">
                                    {warning}
                                </p>
                            )}
                            {newOrder && newOrder.customer ? (
                                <>
                                    {displayOrderContent ? (
                                        <>
                                            <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                                <div className="z-40 col-span-3">
                                                    <SearchSelector
                                                        displayKey="name"
                                                        secondaryKey="ref"
                                                        label="Produit*"
                                                        options={products}
                                                        onSelect={(e: any) => {
                                                            setNewItem(
                                                                (
                                                                    prevCustomer: any,
                                                                ) => ({
                                                                    ...prevCustomer,
                                                                    name: e.name,
                                                                    productId:
                                                                        e._id,
                                                                    _id: e._id,
                                                                    price: e.price,
                                                                    unity: e.unity,
                                                                    ref: e.ref,
                                                                    netWeight:
                                                                        e.netWeight,
                                                                    brutWeight:
                                                                        e.brutWeight,
                                                                    totalPurchasePrice:
                                                                        calculateTotalPurchasePrice(
                                                                            e,
                                                                        ),
                                                                }),
                                                            );
                                                        }}
                                                        inputSmall={true}
                                                    />
                                                </div>
                                                <div className="mt-7 md:mt-0 col-span-1">
                                                    <InputField
                                                        label="Quantité*"
                                                        placeholder="1"
                                                        id="quantity"
                                                        type="number"
                                                        onChange={
                                                            handleItemChange
                                                        }
                                                        inputSmall={true}
                                                    />
                                                </div>
                                                <div className="mt-7 md:mt-0 col-span-2">
                                                    <InputField
                                                        label="Prix unitaire HT*"
                                                        placeholder="17"
                                                        id="price"
                                                        type="number"
                                                        value={newItem.price}
                                                        onChange={
                                                            handleItemChange
                                                        }
                                                        inputSmall={true}
                                                    />
                                                </div>
                                                <div className="col-span-3">
                                                    <button
                                                        className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${
                                                            alreadyExist(
                                                                newItem.productId,
                                                            )
                                                                ? 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                                                                : 'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'
                                                        } px-5 py-2 text-sm font-medium text-white`}
                                                        onClick={() => {
                                                            if (
                                                                !alreadyExist(
                                                                    newItem.productId,
                                                                )
                                                            ) {
                                                                if (
                                                                    !newItem.productId ||
                                                                    !newItem.quantity ||
                                                                    !newItem.price
                                                                )
                                                                    return toast.error(
                                                                        'Veuillez remplir tous les champs',
                                                                    );
                                                                setNewOrder(
                                                                    (
                                                                        prevCustomer: any,
                                                                    ) => ({
                                                                        ...prevCustomer,
                                                                        items: [
                                                                            ...prevCustomer.items,
                                                                            newItem,
                                                                        ],
                                                                        totalPrice:
                                                                            getTotalHT(
                                                                                [
                                                                                    ...prevCustomer.items,
                                                                                    newItem,
                                                                                ],
                                                                            ),
                                                                    }),
                                                                );
                                                                setNewItem({
                                                                    discountType:
                                                                        'none',
                                                                    discountValue: 0,
                                                                    ...newItem,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        Ajouter
                                                    </button>
                                                </div>
                                            </div>
                                            <p className="mt-4 text-base text-gray-600">
                                                Récapitulatif de la commande
                                            </p>
                                            {newOrder && (
                                                <OrderTable
                                                    order={newOrder}
                                                    handleQtyChange={
                                                        updateQuantity
                                                    }
                                                    handlePriceChange={
                                                        updateUnityPrice
                                                    }
                                                    handleDiscountChange={
                                                        updateDiscount
                                                    }
                                                    handleCommentChange={
                                                        updateComment
                                                    }
                                                    handleDiscountTypeChange={
                                                        updateDiscountType
                                                    }
                                                    onDelete={deleteItem}
                                                />
                                            )}
                                            {newOrder &&
                                                newOrder.items.length === 0 && (
                                                    <div className="">
                                                        <p className="mt-5 text-lg text-gray-600 text-center">
                                                            Aucun commande en
                                                            cours à cette date
                                                        </p>
                                                        {customerLastOrder &&
                                                            customerLastOrder.length >
                                                                0 && (
                                                                <div className="py-3 flex justify-center">
                                                                    <button
                                                                        className="flex gap-1 text-sm text-white py-2 px-3 bg-gradient-to-r from-toola-400 to-toola-500 rounded-full"
                                                                        onClick={() => {
                                                                            setIARefreshCounter(
                                                                                IARefreshCounter +
                                                                                    1,
                                                                            );
                                                                            setDisplayOrderContent(
                                                                                true,
                                                                            );
                                                                            setOrderWithAI();
                                                                        }}
                                                                    >
                                                                        <BsStars className="flex my-auto text-white" />
                                                                        Pré-remplir
                                                                        avec
                                                                        l'IA
                                                                    </button>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                        </>
                                    ) : (
                                        <div className="justify-center">
                                            {customerLastOrder &&
                                                customerLastOrder.length >
                                                    0 && (
                                                    <div className="py-3 flex justify-center">
                                                        <button
                                                            className="flex gap-1 text-white py-2 px-3 bg-gradient-to-r from-toola-400 to-toola-500 rounded-full hover:from-toola-500 hover:to-toola-400"
                                                            onClick={() => {
                                                                setIARefreshCounter(
                                                                    IARefreshCounter +
                                                                        1,
                                                                );
                                                                setDisplayOrderContent(
                                                                    true,
                                                                );
                                                                setOrderWithAI();
                                                            }}
                                                        >
                                                            <BsStars className="flex my-auto text-white" />
                                                            Pré-remplir avec
                                                            l'IA
                                                        </button>
                                                    </div>
                                                )}
                                            <p
                                                className="text-center text-sm cursor-pointer"
                                                onClick={() =>
                                                    setDisplayOrderContent(true)
                                                }
                                            >
                                                Remplir manuellement
                                            </p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="flex justify-center">
                                    <p className="text-lg text-gray-600">
                                        Veuillez sélectionner un client avant de
                                        continuer
                                    </p>
                                </div>
                            )}
                        </div>
                        {newOrder.items.length > 0 && (
                            <div className="flex gap-10">
                                <div className="mt-5 flex gap-[15px]">
                                    <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                        Total HT
                                    </p>
                                    <p className="text-lg font-bold text-navy-700 dark:text-white">
                                        {newOrder.totalPrice.toFixed(2)}€
                                    </p>
                                </div>
                                {totalNetWeight() > 0 && (
                                    <div className="mt-5 flex gap-[15px]">
                                        <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                            Poids net total:
                                        </p>
                                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                                            {totalNetWeight()}
                                            Kg
                                        </p>
                                    </div>
                                )}
                                {totalBrutWeight() > 0 && (
                                    <div className="mt-5 flex gap-[15px]">
                                        <p className="col-span-4 sm:col-span-1 text-lg text-navy-700 dark:text-white">
                                            Poids brut total:
                                        </p>
                                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                                            {totalBrutWeight()}
                                            Kg
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Card>
                <div className="mt-5 ml-5 grid grid-cols-1 sm:grid-cols-12 gap-4">
                    <button
                        className={`linear col-span-12 sm:col-span-3 flex items-center justify-center rounded-xl px-10 py-2 text-xs font-medium text-white ${
                            isValidOrder(newOrder)
                                ? 'bg-[#0260CB] hover:bg-[#01499b] active:bg-[#024086]'
                                : 'bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default'
                        }`}
                        onClick={() => {
                            isValidOrder(newOrder) && addOrder();
                        }}
                    >
                        Ajouter la commande
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateRecurringOrder;
