import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/hooks';

import InvoicesTable from './components/invoicesTable';
import {
    downloadAccountingExport,
    getPaginatedInvoices,
    sendInvoiceEmail,
    updateEmailModel,
} from 'interfaces/invoice';
import ExportModal from './components/ExportModal';
import Pagination from 'components/pagination/Pagination';
import EmailModal from './components/EmailModal';
import NewCreditNoteModal from './components/NewCreditNoteModal';

const ListInvoices = () => {
    const nav = useNavigate();
    const params = useParams();
    const [invoices, setInvoices] = useState(null);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState('all');
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [invoicesMeta, setInvoicesMeta] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        isOpen: isEmailSenderModalOpen,
        onOpen: onEmailSenderModalOpen,
        onClose: onEmailSenderModalClose,
    } = useDisclosure();

    const {
        isOpen: isCreditNoteModalOpen,
        onOpen: onCreditNoteModalOpen,
        onClose: onCreditNoteModalClose,
    } = useDisclosure();

    const onEmailModalOpen = (invoiceId: string) => {
        setSelectedInvoiceId(invoiceId);
        onEmailSenderModalOpen();
    };

    const fetchInvoices = async (
        page: number,
        limit: number,
        search: string = null,
        status: string = null,
    ) => {
        const month = Number(params.month);
        const year = Number(params.year);
        const res = await getPaginatedInvoices(
            limit,
            page,
            month,
            year,
            search,
            status,
        );
        if (res && res.data) {
            setInvoices(res.data.data);
            setInvoicesMeta(res.data.meta);
            setPage(page);
            setLimit(limit);
        }
    };

    const getAccountingExport = async () => {
        try {
            const blob = await downloadAccountingExport();
            if (!blob) return;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `export-${new Date().toLocaleDateString()}.csv`;
            link.click();
            onClose();
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const sendEmail = async (name: string, content: string) => {
        const res = await updateEmailModel(name, content);
        if (res) {
            onEmailSenderModalClose();
            const invoice = await sendInvoiceEmail(
                selectedInvoiceId,
                name,
                content,
            );
            if (invoice) {
                window.location.reload();
            }
        }
    };

    const onFilter = async (newSearch: string, newStatus: string) => {
        if (newSearch === '') setSearch('');
        if (newSearch) setSearch(newSearch);
        if (newStatus) {
            fetchInvoices(1, limit, search, newStatus);
            setStatus(newStatus);
        }
    };

    const onRefresh = () => {
        fetchInvoices(page, limit, search, status);
    };

    // Update filters
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchInvoices(1, limit, search, status);
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // Default init
    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        fetchInvoices(page, limit, search, status);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                <ExportModal
                    isOpen={isOpen}
                    onClose={onClose}
                    handleExport={getAccountingExport}
                />
                <EmailModal
                    isOpen={isEmailSenderModalOpen}
                    onClose={onEmailSenderModalClose}
                    sendEmail={(n: string, object: string) =>
                        sendEmail(n, object)
                    }
                    selectedInvoiceId={selectedInvoiceId}
                />
                <NewCreditNoteModal
                    isOpen={isCreditNoteModalOpen}
                    onClose={onCreditNoteModalClose}
                />
                <div className="col-span-12 h-full w-full rounded-[20px]">
                    {invoices && (
                        <InvoicesTable
                            tableData={invoices}
                            onOpen={onOpen}
                            onEmailSenderModalOpen={onEmailModalOpen}
                            invoicesMeta={invoicesMeta}
                            onFilter={(search: string, status: string) =>
                                onFilter(search, status)
                            }
                            onRefresh={onRefresh}
                            onCreateCreditNote={onCreditNoteModalOpen}
                        />
                    )}
                </div>

                {/* pagination */}
                <Pagination
                    extra="col-span-12"
                    text={false}
                    type="factures"
                    maxItems={invoicesMeta ? invoicesMeta.total : 12}
                    itemsPerPage={[10, 20, 30, 40, 50]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchInvoices(page, limit, search, status)
                    }
                />
            </div>
        </>
    );
};

export default ListInvoices;
