import axios from 'axios';

import env from 'variables/config';
import { errorHandler } from './utils';

axios.defaults.baseURL = env.apiUrl;

const config = (isExternalPage: boolean = false) => {
    return {
        headers: {
            Authorization:
                'Bearer ' +
                localStorage.getItem(
                    isExternalPage ? 'delivery-token' : 'token',
                ),
        },
    };
};

export const getPaginatedRecurringOrders = async (
    page: number,
    limit: number,
    search: string,
) => {
    const res: any = await axios
        .get(
            `/recurring-orders?limit=${limit}&page=${page}&search=${search}`,
            config(),
        )
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getRecurringOrder = async (id: string) => {
    const res = await axios
        .get(`/recurring-orders/${id}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const updateRecurringOrder = async (customer: any) => {
    const id = customer._id;
    delete customer._id;
    const res: any = await axios
        .put(`/recurring-orders/${id}`, customer, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deleteRecurringOrder = async (id: string) => {
    const res: any = await axios
        .delete(`/recurring-orders/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createRecurringOrder = async (customer: any) => {
    const res = await axios
        .post('/recurring-orders', customer, config())
        .catch(errorHandler);
    return res.data;
};
