import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';

import Card from 'components/card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { generateCreditNoteFromScratch } from 'interfaces/invoice';
import SearchSelector from 'components/fields/SearchSelector';
import { getCustomers } from 'interfaces/customer';
import { getProducts } from 'interfaces/products';

const NewCreditNoteModal = (props: { isOpen: any; onClose: any }) => {
    const { isOpen, onClose } = props;
    const [products, setProducts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [creditNote, setCreditNote] = useState({
        customerId: null,
        comment: null,
        totalHT: 0,
        productName: null,
        productRef: null,
        quantity: 1,
        vat: 5.5,
    });

    const initCustomers = async () => {
        const res = await getCustomers();
        const newCustomers = res.data.map((customer: any) => ({
            ...customer,
            company: customer.displayName || customer.company,
        }));
        if (res) setCustomers(newCustomers);
    };

    const handleClose = () => {
        onClose();
        setCreditNote({
            customerId: null,
            comment: null,
            totalHT: 0,
            productName: null,
            productRef: null,
            quantity: 1,
            vat: 5.5,
        });
    };

    const generateCreditNote = async () => {
        const res = await generateCreditNoteFromScratch(creditNote);
        if (res.data) {
            onClose();
            setCreditNote({
                customerId: null,
                comment: null,
                totalHT: 0,
                productName: null,
                productRef: null,
                quantity: 1,
                vat: 5.5,
            });
            window.location.reload();
        }
    };

    const initProducts = async () => {
        const res = await getProducts();
        if (res) setProducts(res.data);
    };

    useEffect(() => {
        initCustomers();
        initProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-1 text-2xl font-bold">
                            Créer un avoir
                        </h1>
                        <div className="z-50 col-span-3">
                            <SearchSelector
                                label="Client*"
                                displayKey="company"
                                options={customers}
                                onSelect={(e: any) => {
                                    setCreditNote((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        customerId: e._id,
                                    }));
                                }}
                                inputSmall={true}
                            />
                        </div>
                        <div className="mt-8 flex gap-2">
                            <SearchSelector
                                displayKey="name"
                                secondaryKey="ref"
                                label="Produit*"
                                options={products}
                                onSelect={(e: any) =>
                                    setCreditNote({
                                        ...creditNote,
                                        productName: e.name,
                                        productRef: e.ref,
                                    })
                                }
                                inputSmall={true}
                            />
                            <InputField
                                label="Quantité*"
                                placeholder="Quantité"
                                id="quantity"
                                value={creditNote.quantity}
                                type="number"
                                onChange={(e: any) =>
                                    setCreditNote({
                                        ...creditNote,
                                        quantity: e.target.value,
                                    })
                                }
                                inputSmall={true}
                            />
                            <InputField
                                label="TVA (%)"
                                placeholder="TVA"
                                id="vat"
                                value={creditNote.vat}
                                type="number"
                                onChange={(e: any) =>
                                    setCreditNote({
                                        ...creditNote,
                                        vat: e.target.value,
                                    })
                                }
                                inputSmall={true}
                            />
                        </div>
                        <InputField
                            label="Montant total HT*"
                            placeholder="Montant total HT"
                            id="totalHT"
                            value={creditNote.totalHT}
                            type="number"
                            extra="mt-2"
                            onChange={(e: any) =>
                                setCreditNote({
                                    ...creditNote,
                                    totalHT: e.target.value,
                                })
                            }
                            inputSmall={true}
                        />
                        <div className="mt-2">
                            <TextField
                                label="Commentaire"
                                id="comment"
                                placeholder="Votre commentaire..."
                                value={creditNote.comment}
                                cols={30}
                                rows={2}
                                onChange={(e: any) =>
                                    setCreditNote({
                                        ...creditNote,
                                        comment: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mt-5 flex justify-between gap-2">
                            <button
                                onClick={handleClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            {creditNote.vat &&
                                creditNote.customerId &&
                                creditNote.productName && (
                                    <button
                                        className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                        onClick={generateCreditNote}
                                    >
                                        Créer la facture d'avoir
                                    </button>
                                )}
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NewCreditNoteModal;
