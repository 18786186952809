export const calculateTotalPurchasePrice = (product: any): number => {
    if (!product) return 0;

    if (product.purchasePrice) {
        return Number(product.purchasePrice);
    }

    const subProducts = product.subProducts;
    if (!subProducts || subProducts.length === 0) return 0;

    let totalPurchasePrice = 0;

    subProducts.forEach((subProduct: any) => {
        const quantity = subProduct?.quantity || 1;
        const subProductPurchasePrice = calculateTotalPurchasePrice(
            subProduct.id,
        );
        totalPurchasePrice += quantity * subProductPurchasePrice;
    });

    return totalPurchasePrice;
};
