import PieChart from 'components/charts/PieChart';
import Card from 'components/card';
import { useEffect, useState } from 'react';

const PieChartCard = (props: { data: any }) => {
    const { data } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [pieChartOptions, setPieChartOptions] = useState({
        labels: ['Your files', 'System', 'Empty', 'test'],
        chart: {
            width: '200px',
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
        },
        legend: {
            show: true,
            position: 'bottom',
        },
        dataLabels: {
            enabled: false,
        },
        // hover: { mode: null },
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    },
                },
            },
        },
        // fill: {
        //     colors: ['#4299E1', '#3182CE', '#2B6CB0'],
        // },
        tooltip: {
            enabled: true,
            theme: 'dark',
            y: {
                formatter: function (val: any) {
                    return val + ' €';
                },
            },
        },
    });
    const [pieChartData, setPieChartData] = useState([63, 25, 12, 5]);

    useEffect(() => {
        console.log(data);
        if (data) {
            const labels = Object.keys(data).slice(0, 5);
            const values: any = Object.values(data).slice(0, 5);
            console.log(labels, values);
            setPieChartOptions({
                ...pieChartOptions,
                labels,
            });
            setPieChartData(values);
            setIsLoaded(true);
        }
    }, [data]);

    return (
        <Card extra="rounded-[20px] p-3 border">
            <div className="flex flex-row justify-between px-3 pt-2">
                <div>
                    <div className="text-lg font-bold text-navy-700 dark:text-white">
                        Répartition par clients
                    </div>
                    <p>Top 5 clients</p>
                </div>
            </div>

            <div className="mt-4 mb-auto flex h-[250px] w-full items-center justify-center">
                {isLoaded && (
                    <PieChart
                        chartOptions={pieChartOptions}
                        chartData={pieChartData}
                    />
                )}
            </div>
        </Card>
    );
};

export default PieChartCard;
